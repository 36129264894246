<style scoped>

.experience:hover {
  background-color: #e83737 !important;
}

.download:hover {
  background-color: #1C232B !important;
  border: 1px solid white;
}

.zoom-animation {
  animation: zoom 1.1s infinite alternate;
}

@keyframes zoom {
  from {
    transform: scale(1); /* 起始时大小不变 */
  }
  to {
    transform: scale(1.1); /* 结束时放大到1.5倍 */
  }
}

.buy-button {
  width: 220px;
  height: 35px;
  font-size: 16px;
  margin-top: 30px;
  background-color: #3E444B;
  color: white;
}

.buy-button:hover {
  background-color: red;
  color: white;
}

.bottom {
  width: 220px;
  height: 35px;
  font-size: 16px;
  margin-top: 30px;
  background-color: #3E444B;
  color: white;
}

.bottom:hover {
  background-color: rgba(227, 11, 32, 0.93) !important;
  color: white;
}


.dian {
  font-size: 18px;
  display: inline-block;
  margin-bottom: 7px;
}

.text-wrapper {
  position: absolute; /* 绝对定位允许我们覆盖在其他元素上 */
  top: 180px; /* 调整以匹配图像的位置 */
  left: -180px; /* 或者你可以使用其他值来将文本放置在图像的特定部分上 */
  display: flex; /* 使用Flexbox来居中文本，但这不是必需的 */
}

.big-picture {
  font-size: 20px;
  color: #CCCCCC;
}

/*动画*/
.initial-state {
  opacity: 0;
  transform: translateY(50px);
  transition: transform 1.5s ease, opacity 1.5s ease;
}

.float-up {
  opacity: 1;
  transform: translateY(0);
}

@keyframes slideUp {
  from {
    transform: translateY(100px); /* 开始位置在下方100px处 */
    opacity: 0; /* 从完全透明开始 */
  }
  to {
    transform: translateY(0); /* 结束位置为原始位置 */
    opacity: 1; /* 结束时完全不透明 */
  }
}

/* 应用动画的类 */
.slide-up {
  animation: slideUp 1s ease-out forwards; /* 动画名称、持续时间、缓动函数、动画填充模式 */
}
</style>

<template>

  <div style="background-color: #1C232B;padding: 1px;width: 100%">
    <!--  第一个大容器-->
    <div style="text-align: center;">
      <div style="margin-left: 19%;">

        <div style="height: 900px;padding: 1px;margin-top: 100px;">

          <div style="width: 75%;float: left;text-align: left;">
            <div style="color: white;">
              <div style="height: 10%;text-align: center">
                <h1 style="font-size: 80px;letter-spacing: 6px;font-weight: normal;text-align: center">
                  用好模型，轻松提升项目品质</h1>
                <!--                <img :src="t('home.img')" alt="" style="height: 50%;">-->

                <div style="margin-top: 70px;text-align: center">
                  <!--                  <span style="font-size: 40px;">{{ t('home.Brief1') }}</span>-->
                  <h3 style="font-size: 30px;font-weight: normal;">
                    以创新、高效、简单的方式用好模型，解决建而无用的数字化变现难题。</h3>
                </div>
              </div>
            </div>

            <div style="color: #CCCCCC;font-size: 20px;float: left;line-height: 35px;margin-top: 50px">
              <!--              <span>{{ t('home.Brief2') }}</span>-->
              <span style="opacity: 70%;font-size: 20px; color: #FFFFFF">全球独创BIM技术与效果模型一体化的双模协同引擎，以唯一数据源驱动 "轻量化模型查阅" 与 "高品质迭代渲染"，
                并支持团队云端模型共享与问题协作，基于BIM虚拟现实的麟云LinYunBIM平台轻松实现项目品质管控。</span>
              <!--              <br>-->
              <!--              &lt;!&ndash;              <span>{{ t('home.Brief3') }}</span>&ndash;&gt;-->
              <!--              <span>以唯一数据源驱动“轻量化模型查阅”与“高品质迭代渲染”，并支持团队云端模型共享与问题协作，</span>-->
              <!--              <br>-->
              <!--              &lt;!&ndash;              <span>{{ t('home.Brief4') }}</span>&ndash;&gt;-->
              <!--              <span>基于BIM虚拟现实的麟云LinYunBIM平台助力轻松实现项目品质全程管控。</span>-->
            </div>

            <div style="color: #CCCCCC;font-size: 20px;float: left;line-height: 35px;width: 100%">
              <!--              <span>{{ t('home.Brief2') }}</span>-->
              <span>功能特点:</span>
              <br>
              <!--              <span>{{ t('home.Brief3') }}</span>-->
              <span style="margin-left: 50px">模型轻量化查阅：20GB模型10秒打开，普通电脑流畅运行;</span>
              <br>
              <!--              <span>{{ t('home.Brief4') }}</span>-->
              <span style="margin-left: 50px">高品质迭代渲染：BIM模型快速效果实时呈现，与轻量化模型同源迭代；</span>
              <br>
              <span
                  style="margin-left: 50px">模型与问题协同：项目模型云端上传下载，设计/施工模型审查问题同步协作；</span>
            </div>


            <div style="margin-top: 100px;float: right">
              <router-link to="/download" style="text-decoration: none">
                <el-button type="danger"
                           style="font-size: 20px;font-weight: 700;height: 50px;background-color: #e83737;border-radius: 10px;"
                           class="download slide-up"> <!-- 添加了 slide-up 类 -->
                  {{ t('home.FreeDownload') }}
                </el-button>
              </router-link>

              <el-button type="info" style="font-size: 20px;font-weight: 700;height: 50px;background-color: #1C232B;margin-left: 30px;
            border-radius: 10px" class="experience slide-up" @click="goToPricePage"> <!-- 同样添加了 slide-up 类 -->
                价格
              </el-button>
            </div>
          </div>


        </div>
      </div>
    </div>


    <!--  第二个大容器-->
    <div ref="textContainer1">
      <!--      <span style="font-size: 90px;color: white;margin-left: 30px">{{ t('home.UnityInDiversity') }}</span><br>-->
      <!--      <h1 style="font-size: 80px;letter-spacing: 6px;font-weight: normal;text-align: center">用好模型，轻松提升项目品质</h1>-->
      <span style="font-size: 60px;color: white;margin-left: 30px;display: inline-block;" ref="floatingText1"
            :class="{'float-up': shouldFloatUp1}" class="initial-state">简单高效</span><br><br>
      <!--      <span style="font-size: 90px;color: white;margin-left: 50px">{{ t('home.QualityInDetails') }}</span><br><br>-->
      <span style="font-size: 60px;color: white;margin-left: 50px;display: inline-block;" ref="floatingText1"
            :class="{'float-up': shouldFloatUp1}" class="initial-state">三分钟上手</span><br><br>
      <br><br>
      <span style="opacity: 70%;font-size: 20px; color: white;display: inline-block;" ref="floatingText1"
            :class="{'float-up': shouldFloatUp1}"
            class="initial-state">界面布局简洁直观，三维操作单手搞定，视点、测量、剖切、属性功能简单高效；汇报、宣传、交付轻松胜任</span>
    </div>
    <!--    第一张图片-->
    <!--    <img :src="t('home.img1')" alt="" style="height: 62.5%;width: 62.5%">-->

    <video controls width="75%" autoplay muted loop style="margin-top: 6%">
      <source src="http://video.linyunbim.com/website-video/Simple-and-efficient.mp4" type="video/mp4">
    </video>


    <!--  第三个大容器-->
    <div style="height: 350px;margin-top: 200px;" ref="textContainer2">
      <!--      <span style="font-size: 90px;color: white">{{ t('home.SmoothToStart') }}</span><br>-->

      <span style="font-size: 60px;color: white;margin-left: 30px;display: inline-block;" ref="floatingText2"
            :class="{'float-up': shouldFloatUp2}" class="initial-state">轻量整合</span><br><br>
      <!--      <span style="font-size: 90px;color: white;">{{ t('home.SimpleButExceptional') }}</span><br><br>-->
      <span style="font-size: 60px;color: white;margin-left: 30px;display: inline-block;" ref="floatingText2"
            :class="{'float-up': shouldFloatUp2}" class="initial-state">30万平亦可胜任</span><br><br><br><br>
      <!--      <span style="color: azure;font-size: 25px;">{{ t('home.SupportLarge') }}</span>-->
      <span style="opacity: 70%;color: white;font-size: 20px;display: inline-block;" ref="floatingText2"
            :class="{'float-up': shouldFloatUp2}" class="initial-state">整合全球技术力量，自研 "麒麟" 轻量化算法，导入Revit，SU，Rhino，3DMax等软件模型进行各方多专业模型整合查阅，扫除 "用好模型" 的关键障碍</span>
    </div>
    <!--    第二张图片-->
    <!--    <img :src="t('home.img2')" alt="" style="height: 62.5%;width: 62.5%">-->

    <video controls width="75%" autoplay muted loop>
      <source src="http://video.linyunbim.com/website-video/Lightweight-integration.mp4" type="video/mp4">
    </video>

    <!--  第四个大容器-->
    <div style="height: 350px;margin-top: 200px;" ref="textContainer3">
      <!--      <span style="font-size: 90px;color: white">{{ t('home.CollaborationAndCommunication') }}</span><br>-->
      <span style="font-size: 60px;color: white;margin-left: 30px;display: inline-block;" ref="floatingText3"
            :class="{'float-up': shouldFloatUp3}" class="initial-state">双模引擎</span><br><br>
      <!--      <span style="font-size: 90px;color: white;">{{ t('home.SeamlesslyAndEffortlessly') }}</span><br><br>-->
      <span style="font-size: 60px;color: white;margin-left: 30px;display: inline-block;" ref="floatingText3"
            :class="{'float-up': shouldFloatUp3}" class="initial-state">技术与效果统一</span><br><br><br><br>
      <!--      <span style="color: azure;font-size: 25px;">{{ t('home.InAnAdvance') }}</span>-->
      <!--      <span style="color: azure;font-size: 25px;">{{ t('home.InAnAdvance') }}</span>-->
      <span style="opacity: 70%;color: white;font-size: 20px;display: inline-block;" ref="floatingText3"
            :class="{'float-up': shouldFloatUp3}"
            class="initial-state">基于唯一的模型数据源，从BIM技术模型查阅到高品质场景渲染仅需一键切换，</span><br>
      <span style="opacity: 70%;color: white;font-size: 20px;margin-top: 5px;display: inline-block;" ref="floatingText3"
            :class="{'float-up': shouldFloatUp3}" class="initial-state">让大家从Navisworks，Fuzor，Lumion，D5，Enscape，UE5等众多软件中解放出来，专注于项目品质提升</span>
    </div>
    <!--    第三张图片-->
    <!--    <img :src="t('home.img3')" alt="" style="height: 62.5%;width: 62.5%">-->

    <div ref="videoContainer" style="width: 100%; position: relative;">
      <video controls width="75%" autoplay muted loop ref="scaledVideo" :style="videoStyle">
        <source src="http://video.linyunbim.com/website-video/Effect-switching.mp4" type="video/mp4">
      </video>
    </div>


    <!--  第五个大容器-->
    <div style="height: 350px;" ref="textContainer4">
      <!--      <span style="font-size: 90px;color: white">{{ t('home.NextGeneration') }}</span><br>-->
      <span style="font-size: 60px;color: white;margin-left: 30px;display: inline-block;" ref="floatingText4"
            :class="{'float-up': shouldFloatUp4}" class="initial-state">提升品质</span><br><br>
      <!--      <span style="font-size: 90px;color: white;">{{ t('home.BIMCloudPlatform') }}</span><br><br>-->
      <span style="font-size: 60px;color: white;margin-left: 30px;display: inline-block;" ref="floatingText4"
            :class="{'float-up': shouldFloatUp4}" class="initial-state">筑牢团队生命线</span><br><br><br><br>
      <!--      <span style="color: azure;font-size: 25px;">{{ t('home.TheWorld') }}</span>-->
      <!--      <span style="color: azure;font-size: 25px;">{{ t('home.TheWorld') }}</span>-->
      <span style="opacity: 70%;color: white;font-size: 20px;display: inline-block;" ref="floatingText4"
            :class="{'float-up': shouldFloatUp4}"
            class="initial-state">从全专业BIM模型整合--BIM技术与效果协同--设计与施工BIM深化--到项目最终高品质呈现，</span><br>
      <span style="opacity: 70%;color: white;font-size: 20px;display: inline-block;margin-top: 5px" ref="floatingText4"
            :class="{'float-up': shouldFloatUp4}"
            class="initial-state">过程迭代直至项目品质达到 "全新高度" ，成就团队BIM价值变现与未来核心竞争力。</span>
    </div>

    <video controls width="75%" autoplay muted loop>
      <source src="http://video.linyunbim.com/website-video/Improve-quality.mp4" type="video/mp4">
    </video>


    <!--  第五个大容器-->
    <div style="height: 850px;margin-top: 300px" ref="textContainer6">
      <!--      <span style="font-size: 90px;color: white">{{ t('home.NextGeneration') }}</span><br>-->
      <span style="font-size: 60px;color: white;margin-left: 30px;display: inline-block;" ref="floatingText6"
            :class="{'float-up': shouldFloatUp6}" class="initial-state">全球首款</span><br><br>
      <!--      <span style="font-size: 90px;color: white;">{{ t('home.BIMCloudPlatform') }}</span><br><br>-->
      <span style="font-size: 60px;color: white;margin-left: 30px;display: inline-block;" ref="floatingText6"
            :class="{'float-up': shouldFloatUp6}" class="initial-state">基于UE5引擎BIM平台</span><br><br><br><br>

      <span style="opacity: 70%;color: white;font-size: 20px;display: inline-block;" ref="floatingText6"
            :class="{'float-up': shouldFloatUp6}"
            class="initial-state">面向未来高品质项目，新生代BIM虚拟现实平台</span><br>
      <div style="margin-top: 60px">
        <img src="http://video.linyunbim.com/website-video/texing.png" alt="" style="width: 75%;">
      </div>
    </div>


    <!--    <div style="position: relative">
          <div style="width: 100%; height: 1250px;">
            <div style="width: 62.5%; height: 102%; margin: 0 auto; align-content: center">
              <img :src="t('home.img4')" alt="" style="width: 100%; height: 98%;">
            </div>
          </div>
        </div>

            第四张图片

            <div style="position: relative">
              <div style="width: 100%; height: 1250px;">
                <div style="width: 62.5%; height: 102%; margin: 0 auto; align-content: center">
                  <img src="linYun4_C.png" alt="" style="width: 100%; height: 98%;">

                  <div class="text-wrapper" style="margin-left: 15%;">
                    <div>
                      <img src="t_shiShi.png" alt="" style="height: 5%; margin-left: -11%">
                      <div style="margin-left: 410px;margin-top: -40px;text-align: left">
                        <span style="font-size: 22px;font-weight: bold;color: white">{{ t('home.DynamicRendering') }}</span>
                      </div>
                      <div style="border: 1px solid red;width: 350px;margin-left: 350px;margin-top: 40px;text-align: left">
                      <span
                          class="big-picture">{{ t('home.BasedOnDynamic') }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-wrapper" style="margin-top: 280px;margin-left: 15%">
                <div>
                  <img src="t_jianRong.png" alt="" style="height: 5%;margin-left: -11%;">
                  <div style="margin-left: 415px;margin-top: -40px;border: 1px solid red;width: 250px;text-align: left">
                          <span
                              style="font-size: 22px;font-weight: bold;color: white">{{
                              t('home.PowerfulCompatibility')
                            }}</span>
                  </div>
                  <div style="border: 1px solid red;width: 350px;margin-left: 350px;margin-top: 40px;text-align: left">
                        <span
                            class="big-picture">{{ t('home.UsingANewly') }}</span>
                  </div>
                </div>
              </div>

              <div class="text-wrapper" style="margin-top: 530px;margin-left: 15%">
                <div>
                  <img src="t_gaoPing.png" alt="" style="height: 5%;margin-left: -11%">
                  <div style="margin-left: 415px;margin-top: -40px;border: 1px solid red;width: 250px;text-align: left">
                    <span style="font-size: 22px;font-weight: bold;color: white">{{ t('home.HighQualityEffects') }}</span>
                  </div>
                  <div style="border: 1px solid red;width: 350px;margin-left: 350px;margin-top: 40px;text-align: left">
                        <span
                            class="big-picture">{{ t('home.WithSimpleMaterial') }}</span>
                  </div>
                </div>
              </div>


              <div class="text-wrapper" style="margin-top: 800px;margin-left: 15%">
                <div>
                  <img src="t_chaoDa.png" alt="" style="height: 5%;margin-left: -11%">
                  <div style="margin-left: 415px;margin-top: -40px;border: 1px solid red;width: 270px;text-align: left">
                          <span style="font-size: 22px;font-weight: bold;color: white">{{
                              t('home.SupportForLargeModels')
                            }}</span>
                  </div>
                  <div style="border: 1px solid red;width: 350px;margin-left: 350px;margin-top: 40px;text-align: left">
                        <span
                            class="big-picture">{{ t('home.NewGraphicsRendering') }}</span>
                  </div>
                </div>
              </div>


              <div class="text-wrapper" style="margin-left: 52%">
                <div>
                  <img src="t_qingLiang.png" alt="" style="height: 5%;margin-left: -12%">
                  <div style="margin-left: 410px;margin-top: -40px;border: 1px solid red;width: 250px;text-align: left">
                    <span style="font-size: 22px;font-weight: bold;color: white">{{ t('home.ModelLightweight') }}</span>
                  </div>
                  <div style="border: 1px solid red;width: 350px;margin-left: 350px;margin-top: 40px;text-align: left">
                        <span
                            class="big-picture">{{ t('home.SupportsModelLightweight') }}</span>
                  </div>
                </div>
              </div>

              <div class="text-wrapper" style="margin-top: 280px;margin-left: 52%">
                <div>
                  <img src="t_jianDan.png" alt="" style="height: 5%;margin-left: -12%">
                  <div style="margin-left: 410px;margin-top: -40px;border: 1px solid red;width: 260px;text-align: left">
                    <span style="font-size: 22px;font-weight: bold;color: white">{{ t('home.SmoothUserExperience') }}</span>
                  </div>
                  <div style="border: 1px solid red;width: 350px;margin-left: 350px;margin-top: 40px;text-align: left">
                        <span
                            class="big-picture">{{ t('home.WithAMinimalist') }}</span>
                  </div>
                </div>
              </div>

              <div class="text-wrapper" style="margin-top: 530px;margin-left: 52%">
                <div>
                  <img src="t_shuJu.png" alt="" style="height: 5%;margin-left: -12%">
                  <div style="margin-left: 410px;margin-top: -40px;border: 1px solid red;width: 250px;text-align: left">
                    <span style="font-size: 22px;font-weight: bold;color: white">{{ t('home.InformationModel') }}</span>
                  </div>
                  <div style="border: 1px solid red;width: 350px;margin-left: 350px;margin-top: 40px;text-align: left">
                        <span
                            class="big-picture">{{ t('home.SupportsTheIntegrationOf') }}</span>
                  </div>
                </div>
              </div>

              <div class="text-wrapper" style="margin-top: 800px;margin-left: 52%">
                <div>
                  <img src="t_jiCheng.png" alt="" style="height: 5%;margin-left: -12%">
                  <div style="margin-left: 410px;margin-top: -40px;border: 1px solid red;width: 250px;text-align: left">
                    <span style="font-size: 22px;font-weight: bold;color: white">{{ t('home.FlexibleIntegration') }}</span>
                  </div>
                  <div style="border: 1px solid red;width: 350px;margin-left: 350px;margin-top: 40px;text-align: left">
                        <span
                            class="big-picture">{{ t('home.IndependentlyDeveloped') }}</span>
                  </div>
                </div>
              </div>
            </div>-->

    <!--  第六个大容器-->
    <div style="height: 550px;margin-top: 550px;"
         ref="workflow" id="workflow">
      <div ref="textContainer5">
        <!--        <span style="font-size: 90px;color: white">{{ t('home.BetweenDifferentSoftware') }}</span><br>-->
        <span style="font-size: 60px;color: white;margin-left: 30px;display: inline-block;" ref="floatingText5"
              :class="{'float-up': shouldFloatUp5}" class="initial-state">无缝衔接</span><br><br>
        <!--        <span style="font-size: 90px;color: white;">{{ t('home.SeamlessConnection') }}</span><br><br>-->
        <span style="font-size: 60px;color: white;margin-left: 30px;display: inline-block;" ref="floatingText5"
              :class="{'float-up': shouldFloatUp5}" class="initial-state">独立插件数据无损</span><br><br>
        <span style="opacity: 70%;color: white;font-size: 20px;display: inline-block;margin-left: 35px"
              ref="floatingText5"
              :class="{'float-up': shouldFloatUp5}"
              class="initial-state">{{ t('home.InstallTheLinYun') }}</span>


      </div>

      <div style="margin-top: 80px;" ref="download" id="download">
        <router-link to="/download" class="link" style="text-decoration: none">
          <el-button class="download" style="height: 50px;font-size: 23px;border-radius: 10px;background-color: #1C232B;color: white;
        margin-left:30px;border: 1px solid #3E444B"><span
              style="opacity: 70%;color: white;font-size: 20px;">{{ t('home.DownloadTheLinYunRevitPlugin') }}</span>
          </el-button>
        </router-link>
      </div>

    </div>

    <div>
      <!--    第五张图片-->
      <img src="linYun5.png" alt="" style="height: 62.5%;width: 62.5%;position: relative">
      <img src="linYun6.png" alt="" class="zoom-animation" style="width: 12%; position: absolute;
      margin-left: -36%; margin-top: 10%;">
    </div>

    <!--  第七个大容器-->
    <div style="height: 1100px;margin-top: 150px;"
         ref="price" id="specific-section">
      <span style="font-size: 60px;color: white">{{ t('home.BuyNow') }}</span><br><br><br><br>

      <span style="opacity: 70%;color: white;font-size: 20px;">{{ t('home.ChooseYourPreferredPlan') }}</span><br><br>

      <div style="display: flex; justify-content: center; align-items: center;">
        <div style="margin-top: 50px;color: white;display: flex;">
          <!--购买条目-->
          <div style="width: 350px;height: 830px;background-color: #3E444B;border-radius: 10px;float: left">
            <br>
            <span style="font-size: 18px;display: block;font-weight: bolder;margin-top: 20px">{{
                t('home.TrialVersion')
              }}</span>
            <br>
            <span style="opacity: 70%;color: white;margin-top: -20px;display: block">{{
                t('home.SuitableForBIMModelReporting')
              }}</span>
            <br>

            <div style="margin-right:30px;height: 60px;">
              <span style="display:block;margin-right: 130px;opacity: 70%;color: white;font-weight: bolder">CNY</span>
              <span style="font-size: 50px;font-weight: bold;margin-right: 105px"
                    v-if="proxy.ip === t('ip.China')">￥0</span>
              <span style="font-size: 50px;font-weight: bold;margin-right: 105px" v-if="proxy.ip === t('ip.Localhost')">￥0</span>
              <span style="font-size: 50px;font-weight: bold;margin-right: 105px"
                    v-if="proxy.ip === t('ip.Foreign')">$0</span>
              <br><br>

            </div>


            <!--              <el-button class="buy-button"  style="width: 300px;border-radius: 8px;margin-top: 60px;background-color: red;border-color: darkgray">-->

            <!--                <a v-if="proxy.ip === t('ip.China')" href="http://47.116.115.210/login?register" style="text-decoration: none;">-->
            <!--                  <span class="link" style="font-size: 16px;font-weight: 700;color: white" >{{ t('home.TryNow') }}</span>-->
            <!--                </a>-->

            <!--                <a v-if="proxy.ip === t('ip.Foreign')" href="http://47.89.229.58/login?register" style="text-decoration: none;">-->
            <!--                  <span class="link" style="font-size: 16px;font-weight: 700;color: white" >{{ t('home.TryNow') }}</span>-->
            <!--                </a>-->

            <!--                <a v-if="proxy.ip === t('ip.Localhost')" href="http://localhost/login?register" style="text-decoration: none;">-->
            <!--                  <span class="link" style="font-size: 16px;font-weight: 700;color: white" >{{ t('home.TryNow') }}</span>-->
            <!--                </a>-->

            <!--              </el-button>-->

            <a v-if="proxy.ip === t('ip.Localhost')" href="http://localhost/login?register"
               style="text-decoration: none;">
              <el-button class="bottom"
                         style="width: 300px;border-radius: 8px;margin-top: 60px;background-color: red;border-color: rgba(155,155,155,0.93)">
                <span class="link" style="font-size: 16px;font-weight: 700;color: white">{{ t('home.TryNow') }}</span>
              </el-button>
            </a>

            <a v-if="proxy.ip === t('ip.China')" href="http://47.116.115.210/login?register"
               style="text-decoration: none;">
              <el-button class="bottom"
                         style="width: 300px;border-radius: 8px;margin-top: 60px;background-color: red;border-color: rgba(155,155,155,0.93)">
                <span class="link" style="font-size: 16px;font-weight: 700;color: white">{{ t('home.TryNow') }}</span>
              </el-button>
            </a>

            <a v-if="proxy.ip === t('ip.Foreign')" href="http://47.89.229.58/login?register"
               style="text-decoration: none;">
              <el-button class="bottom"
                         style="width: 300px;border-radius: 8px;margin-top: 60px;background-color: red;border-color: rgba(155,155,155,0.93)">
                <span class="link" style="font-size: 16px;font-weight: 700;color: white">{{ t('home.TryNow') }}</span>
              </el-button>
            </a>


            <div style="margin-left: -140px;margin-top: 30px;">
              <div
                  style="width: 200px;text-align: left;margin-left: 165px;font-size: 16px;color: hsla(0, 0%, 100%, .8)">
                <span style="display: block">{{ t('home.TrialFeatures') }}</span><br>
              </div>

              <div style="margin-left: 170px;text-align: left;font-size: 15px;color: hsla(0, 0%, 100%, .8)">
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px;">{{ t('home.NumberOfLocalProjects') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.MultipleSoftwarePlugins') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.StorageCapacity') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.ViewpointFunction') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.SlicingFunction') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.MeasurementFunction') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.DirectoryTreeFunction') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.AttributeFunction') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.FourLevel') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.SunlightAdjustment') }}</span>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <br>

                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <span style="margin-left: 15px"></span>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <span style="margin-left: 15px"></span>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <span style="margin-left: 15px"></span>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <span style="margin-left: 15px"></span>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <span style="margin-left: 15px"></span>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <span style="margin-left: 15px"></span>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <span style="margin-left: 15px"></span>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <br>


                <div style="text-align: center; margin-left: -30px; font-size: 12px;font-style: italic">
                  问题反馈：LinYunBIM@aliyun.com
                </div>
              </div>
            </div>
          </div>

          <div
              style="width: 350px;height: 830px;background-color: #3E444B;border-radius: 10px;margin-left: 50px;float: left">
            <br>
            <span style="font-size: 18px;display: block;font-weight: bolder;margin-top: 20px">{{
                t('home.ProfessionalVersion')
              }}</span>
            <br>
            <span style="opacity: 70%;color: white;margin-top: -20px;display: block">{{
                t('home.SuitableForPersonalProjects')
              }}</span>
            <br>
            <div style="margin-right:30px;height: 60px;">
              <span style="display:block;margin-right: 130px;opacity: 70%;color: white;font-weight: bolder">CNY</span>
              <span style="font-size: 50px;font-weight: bold;"
                    v-if="proxy.ip === t('ip.China')">￥{{ yearPrice }}{{ price2 }}</span>
              <span style="font-size: 50px;font-weight: bold;"
                    v-if="proxy.ip === t('ip.Localhost')">￥{{ yearPrice }}{{ price2 }}</span>
              <span style="font-size: 35px;" v-if="proxy.ip === t('ip.Foreign')">$298/Year</span>
              <span v-if="proxy.ip === t('ip.China')">/{{ t('home.Year') }}</span>
              <span v-if="proxy.ip === t('ip.Localhost')">/{{ t('home.Year') }}</span>
              <span v-if="proxy.ip === t('ip.Foreign')">/{{ t('home.Year') }}</span>
            </div>
            <!--            <el-button class="buy-button" @click="buy">{{ t('home.ClickToUpgrade') }}</el-button>-->

            <!--            国内-->
            <el-popover
                v-if="proxy.ip === t('ip.Localhost') || proxy.ip === t('ip.China')"
                ref="popover"
                placement="right"
                :width="250"
                trigger="focus"
            >
              <template #reference>
                <div>
                  <!--                  <el-button class="buy-button" style="width: 120px;float: left;margin-left: 15px">-->
                  <!--                    {{ t('home.WeChatConsultation') }}-->
                  <!--                  </el-button>-->
                  <!--                  <el-button class="buy-button" style="width: 120px;" @click="immediateExperience">-->
                  <!--                    {{ t('home.ImmediateExperience') }}-->
                  <!--                  </el-button>-->
                  <el-button class="buy-button bottom"
                             style="width: 300px;border-radius: 8px;margin-top: 60px;background-color: red;border-color: rgba(155,155,155,0.93)"
                             @click="buy">
                    <span style="font-size: 16px;font-weight: 700">立即订阅</span>
                  </el-button>
                </div>
              </template>
              <img src="/weixin.png" alt="">
            </el-popover>

            <el-dialog v-model="experienceVariable" :title="t('home.ExperienceApplication')" width="450"
                       style="margin-top: 20%;text-align: center">
              <el-col>
                <el-form-item :label="t('home.Username')">
                  <el-input type="text" v-model="experience.username" maxlength="20"/>
                </el-form-item>
              </el-col>

              <el-col>
                <el-form-item :label="t('home.Password')">
                  <el-input type="password" v-model="experience.password" maxlength="20"/>
                </el-form-item>
              </el-col>

              <el-col>
                <el-form-item :label="t('home.Sms')">
                  <el-input type="text" v-model="experience.sms" maxlength="4" style="width: 150px;"/>
                  <el-button type="primary" style="margin-left: 10px" @click="sendCode">{{ t('home.SendEmail') }}
                  </el-button>
                </el-form-item>
              </el-col>

              <template #footer>
                <div style="text-align: center">
                  <el-button type="primary" @click="applyFor">
                    {{ t('home.Confirm') }}
                  </el-button>
                  <el-button @click="experienceVariable = false" style="margin-left: 120px">{{
                      t('home.Cancel')
                    }}
                  </el-button>
                </div>
              </template>
            </el-dialog>

            <!--            国外-->
            <el-button class="buy-button" @click="upgrades" v-if="proxy.ip === t('ip.Foreign')">
              {{ t('home.ClickToUpgrade') }}
            </el-button>


            <div style="margin-left: -140px;margin-top: 30px;">
              <div
                  style="width: 200px;text-align: left;margin-left: 165px;font-size: 16px;color: hsla(0, 0%, 100%, .8)">
                <span style="display: block">{{ t('home.ProfessionalFeatures') }}</span><br>
              </div>
              <div style="margin-left: 170px;text-align: left;font-size: 15px;color: hsla(0, 0%, 100%, .8)">
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.NumberOfLocalProjectsProfessional') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.StorageCapacityProfessional') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.IssueCollaboration') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.ProjectDataMigration') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.ProfessionalTechnicalSupport') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.IncludesAllTrialVersionFeatures') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.QuickList') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.MaterialReplacement') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.MaterialLibrary') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.LocalProject') }}</span>

                <br>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <span style="margin-left: 15px"></span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <span style="margin-left: 15px"></span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <span style="margin-left: 15px"></span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <span style="margin-left: 15px"></span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <span style="margin-left: 15px"></span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <span style="margin-left: 15px"></span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <span style="margin-left: 15px"></span>


              </div>

            </div>
            <div style="text-align: center; font-size: 12px;font-style: italic;">
              <span>技术与售后：专业版微信群</span>
            </div>
          </div>


          <div
              style="width: 350px;height: 830px;background-color: #3E444B;border-radius: 10px;margin-left: 50px;float: left">
            <br>
            <span style="font-size: 18px;display: block;font-weight: bolder;margin-top: 20px">{{
                t('home.EnterpriseVersion')
              }}</span>
            <br>
            <span style="opacity: 70%;color: white;margin-top: -20px;display: block">{{
                t('home.SuitableForProjectsOfVariousEnterpriseScales')
              }}</span>
            <br>
            <div style="margin-left:50px;margin-top: 25px;height: 65px;width: 250px;">
              <span style="font-size: 40px;font-weight: bold;">{{ t('home.ContactUs') }}</span>
              <br>
              <br>

            </div>

            <!--            国内-->
            <el-popover
                ref="popover"
                placement="right"
                :width="270"
                trigger="focus"
                v-if="proxy.ip === t('ip.China') || proxy.ip === t('ip.Localhost')"
                offset="50"
            >
              <template #reference>
                <el-button class="buy-button bottom"
                           style="width: 300px;border-radius: 8px;background-color: red;border-color: rgba(155,155,155,0.93)">
                  <span style="font-size: 16px;font-weight: 700">{{ t('home.WeChatConsultation') }}</span>
                </el-button>
              </template>
              <img src="/weixin.png" alt="">
            </el-popover>

            <!--            国外-->
            <el-popover
                ref="popover"
                placement="right"
                :width="250"
                trigger="focus"
                v-if="proxy.ip === t('ip.Foreign') "
            >
              <template #reference>
                <el-button class="buy-button" @click="Consultation">{{ t('home.EmailConsultation') }}</el-button>
              </template>
              <div style="text-align: center">
                <span>{{ t('home.ContactCustomerService') }}</span><br>
                <span>qilinbim@aliyun.com</span>
              </div>
            </el-popover>


            <div style="margin-left: -140px;margin-top: 30px;">
              <div
                  style="width: 210px;text-align: left;margin-left: 165px;font-size: 16px;color: hsla(0, 0%, 100%, .8)">
                <span style="display: block">{{ t('home.ProfessionalConfiguration') }}</span>
                <br>
              </div>
              <div style="margin-left: 170px;text-align: left;font-size: 15px;color: hsla(0, 0%, 100%, .8)">
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.NumberOfCloudProjectsEnterprise') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.NumberOfAccountsEnterprise') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.CloudStorageCapacityEnterprise') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.CrossProjectCollaborationEnterprise') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.IssueCollaborationEnterprise') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.CloudBasedModelEnterprise') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.CloudCompressionEnterprise') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.EnterpriseCustomLOGO') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.Material') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.SupportsPaidUpgradesForAccountsProjects') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.SupportsPaidCustomIntegration') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.TechnicalSupportEnterprise') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.IncludesAllReleasedFeatures') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.IncludesAll') }}</span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"><Check/></el-icon></span>
                <span style="margin-left: 15px">{{ t('home.PrioritySupport') }}</span>

                <br>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <span style="margin-left: 15px"></span>
                <br>
                <span class="dian"><el-icon style="font-size: 15px"></el-icon></span>
                <span style="margin-left: 15px"></span>
              </div>
            </div>
            <div style="text-align: center;font-size: 12px;font-style: italic">
            <span>技术与售后：企业版微信群</span>
          </div>
          </div>
        </div>
      </div>


    </div>


    <el-dialog v-model="dialogFormVisible" :title="t('top.AccountLogin')" width="450"
               style="margin-top: 20%;text-align: center">
      <el-form :model="form">
        <el-form-item :label="t('top.Username')" :label-width="formLabelWidth"
                      style="margin-right: 8%;margin-top: 10px">
          <el-input style="width: 330px" v-model="form.username" autocomplete="off"/>
        </el-form-item>

        <el-form-item :label="t('top.Password')" :label-width="formLabelWidth" style="margin-right: 8%">
          <el-input style="width: 330px" type="password" v-model="form.password" autocomplete="off"/>
        </el-form-item>
      </el-form>

      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogFormVisible = false">{{ t('top.Cancel') }}</el-button>
          <el-button type="primary" @click="handleLogin">
            {{ t('top.Login') }}
          </el-button>
        </div>
      </template>
    </el-dialog>


    <el-divider style="width: 100%; margin: 10px auto 10px auto; border-color: #666666"/>
    <div style="width: 100%; height: 30px;">
    <span style="font-size: 13px; color: hsla(0, 0%, 100%, .8); font-style: italic;">
        本站点及软件由"阿里云"提供全站技术支持
    </span>
    </div>

  </div>

</template>

<script lang="js" setup>
import {computed, getCurrentInstance, nextTick, onMounted, onUnmounted, reactive, ref, watch} from 'vue'
import axios from "axios";
import {ElMessage, ElMessageBox} from "element-plus";
import JSEncrypt from "jsencrypt";
import {useRoute, useRouter} from 'vue-router';

import {useI18n} from 'vue-i18n';
import router from "@/router";

const {proxy} = getCurrentInstance();

const {t} = useI18n();
// 当前语言的响应式变量

const route = useRoute();

const dialogFormVisible = ref(false)
const form = reactive({
  username: '',
  password: '',
  tenantName: '',
  tenantId: '',
})

const price = ref(null);
const priceRef = ref(null);
//点击价格下滑
const scrollToSpecificSection = () => {
  if (priceRef.value) {
    priceRef.value.scrollIntoView({behavior: 'smooth'});
  }
};


const workflow = ref(null);
const workflowRef = ref(null);
//点击工作流下滑
const scrollToSpecificSection2 = () => {
  if (workflowRef.value) {
    workflowRef.value.scrollIntoView({behavior: 'smooth'});
  }
};

const download = ref(null);
const downloadRef = ref(null);

const scrollToSpecificSection3 = () => {
  if (downloadRef.value) {
    downloadRef.value.scrollIntoView({behavior: 'smooth'});
  }
};

const local = ref();
const user = ref({
  userId: '',
  accessToken: '',
  username: '',
  password: '',
  tenantId: '',
  nickname: '',
  version: '',
})


const formLabelWidth = '100px';
const publicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAn8wru5QjuYhj7pFNFdJroP5JmLQHbciRiIXrjlA4mfk+DFhJ8sPiiKQmZxDjfjip++tcChpKfhIqGrlVe3lJiojfV1wUaB' +
    'nf8GYc0+gJQuy1u94VYtwYC7Rdv/RIhMil2yiIsD+QX2c/gjEXxTXqO87KSNUdFJKCxHLUE3MY+6QAcV2WV4cp/z/x+BhYSZLG6+o8hKjyLtDMfTuJwZj2398QTcZ/6WlZWg2ScKUUxZX+BUj8GujT2jQk' +
    'WDMbvtLuBXT7KDR5OXo0cZNULAH9waaBd3rezzZCp8behKGPBeBbi2/CJRKcwDcJjzB51vrXwAW78mOvQ4m2UwS1culwcwIDAQAB'

const encryptor = new JSEncrypt();
encryptor.setPublicKey(publicKey);
const handleLogin = () => {
  axios.post(proxy.ip + ':48080/admin-api/system/auth/getTenant?username=' + form.username).then((response) => {
    if (response.data.code === 0) {
      form.tenantName = response.data.data
      axios.get(proxy.ip + ':48080/admin-api/system/tenant/get-id-by-name?name=' + form.tenantName).then((response) => {
        if (response.data.code === 0) {
          form.tenantId = response.data.data
          axios.post(proxy.ip + ':48080/admin-api/system/auth/login', form, {
            headers: {
              'Content-Type': 'application/json', // 如果你正在发送JSON数据，这通常是必要的
              'tenant-id': form.tenantId // 在这里添加你的tenant-id
            }
          }).then((response) => {
            if (response.data.code === 0) {
              user.value = response.data.data
              user.value.username = form.username
              form.password = encryptor.encrypt(form.password)
              user.value.password = form.password
              localStorage.setItem('token', JSON.stringify(user.value));
              // 1天
              const expirationTime = 1000 * 60 * 60 * 24 * 30 // 转换为毫秒
              // 计算过期时间戳
              const expirationDate = Date.now() + expirationTime;
              localStorage.setItem('token_expiration', expirationDate.toString());
              ElMessage.success(t('top.LoginSuccessful'))
              dialogFormVisible.value = false
              setTimeout(() => {
                location.reload()
              }, 700)
              window.location.href = proxy.ip + '/pay?14c4b06b824ec593239362517f538b29=' + user.value.username + '&5f4dcc3b5aa765d61d8327deb882cf99=' + user.value.password

            } else {
              ElMessage.error(response.data.msg)
            }
          })
        } else {
          ElMessage.error(response.data.msg)
        }
      })
    } else {
      ElMessage.error(response.data.msg)
    }
  })
}


const buy = () => {
  const tokenInfo = localStorage.getItem("token");
  const tokenObject = JSON.parse(tokenInfo);
  if (tokenObject.tenantId !== 160) {
    ElMessage.success("当前版本不需要升级")
    return
  }


  if (localStorage.getItem("token") == null) {
    dialogFormVisible.value = true
  } else if (localStorage.getItem('token_expiration') !== null && Date.now() > localStorage.getItem('token_expiration')) {
    localStorage.removeItem('token');
    localStorage.removeItem('token_expiration');
    ElMessage.error(t('top.Expire'))
    dialogFormVisible.value = true
  } else {
    window.location.href = proxy.ip + '/pay?14c4b06b824ec593239362517f538b29=' + user.value.username + '&5f4dcc3b5aa765d61d8327deb882cf99=' + user.value.password
  }
}

const tryNow = () => {
  if (proxy.ip === t('ip.China')) {
    window.location.href = 'http://47.116.115.210'
  }
  if (proxy.ip === t('ip.Foreign')) {
    window.location.href = 'http://47.89.229.58'
  }
  if (proxy.ip === t('ip.Localhost')) {
    window.location.href = 'http://localhost'
  }
}


const Consultation = () => {
  // 使用 Clipboard API 复制文本
  navigator.clipboard.writeText('qilinbim@aliyun.com')
      .then(() => {
        ElMessage.success(t('home.EmailCopied'));
      })
      .catch(() => {
        ElMessage.error(t('home.EmailCopiedError'));
      });
}

//国外支付
const upgrades = () => {
  if (localStorage.getItem('token') === null) {
    ElMessage.error(t('home.PleaseLogInFirst'))
    return
  }
  axios.post(proxy.ip + ':48080/admin-api/paypalWebsite/pay?userId=' + user.value.userId).then((response) => {
    if (response.data.code !== 1002003033) {
      window.location.href = response.data.data
    } else {
      ElMessage.warning(t('home.AlreadyProfessional'))
    }
  })
}


const experienceVariable = ref(false)

const immediateExperience = () => {
  experienceVariable.value = true
}

const experience = reactive({
  username: '',
  password: '',
  sms: ''
})
const emailRegex = /^[a-zA-Z0-9_.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*\.[a-z0-9]{2,6}$/;

const isDisable = ref(true);

const sendCode = () => {
  if (experience.username === '') {
    ElMessage.error(t('home.EnterAccount'))
    return
  }
  if (!emailRegex.test(experience.username)) {
    // 邮箱格式不正确，显示错误信息
    ElMessage.error(t('home.EnterCorrectEmail'))
    return; // 停止后续逻辑
  }
  if (isDisable.value === true) {
    axios.post(proxy.ip + ':48080/admin-api/system/user-register/sendEmail?username=' + experience.username + '&&operation=experience').then((res) => {
      if (res.data.data === true) {
        isDisable.value = false
        handleClick()
        ElMessage.success(t('home.SentSuccessfully'))
      } else {
        ElMessage.error(t('home.SentError'))
      }
    })
  }
  if (isDisable.value === false) {
    ElMessage.error(t('home.In30Seconds'))
  }
}

const handleClick = () => {
  if (isDisable.value === false) {
    nextTick(() => {
      // 使用setTimeout来在30秒后重新启用按钮
      setTimeout(() => {
        isDisable.value = true;
      }, 30000); // 30000毫秒 = 30秒
    });
  }
}

const applyFor = () => {
  // if (experience.username === '' || experience.password === '' || experience.sms === ''){
  //   ElMessage.error('请输入完整信息')
  //   return
  // }
  axios.post(proxy.ip + ':48080/admin-api/system/user-register/experience', experience).then((res) => {
    if (res.data.data === true) {
      ElMessage.success(t('home.ApplicationIsSuccessful'))
      experienceVariable.value = false
      return
    }
    if (res.data.code === 1002029007) {
      ElMessage.error(t('home.AccountISNotTheExperienceVersion'))
    }
    if (res.data.code === 1002029008) {
      ElMessage.error(t('home.AccountPasswordIsIncorrect'))
    }
    if (res.data.code === 1002029009) {
      ElMessage.error(t('home.CodeIsIncorrect'))
    }
    if (res.data.code === 1002029010) {
      ElMessage.error(t('home.AccountDisabled'))
    }
    if (res.data.code === 1002029011) {
      ElMessage.error(t('home.SendVerification'))
    }
  })
}


onMounted(() => {
  if (localStorage.getItem('token_expiration') !== null && Date.now() > localStorage.getItem('token_expiration')) {
    localStorage.removeItem('token');
    localStorage.removeItem('token_expiration');
    ElMessage.error(t('home.Expire'))
  }
  if (localStorage.getItem('token') === null) {
    local.value = true;
  } else {
    user.value = JSON.parse(localStorage.getItem('token'))
  }
  if (route.hash === '#specific-section') {
    scrollToSpecificSection();
  } else if (route.hash === '#workflow') {
    scrollToSpecificSection2();
  } else if (route.hash === 'download') {
    scrollToSpecificSection3();
  }
})

watch(route, (to, from) => {
  if (to.hash === '#specific-section' && to.hash !== from.hash) {
    scrollToSpecificSection();
  } else if (to.hash === '#workflow' && to.hash !== from.hash) {
    scrollToSpecificSection2();
  } else if (to.hash === '#download' && to.hash !== from.hash) {
    scrollToSpecificSection3();
  }
}, {deep: true});

const goToPricePage = () => {
  router.push({path: '/', hash: '#specific-section'});
}

const price2 = ref()

const yearPrice = ref()

const getYearPrice = () => {
  axios.get(proxy.ip + ':48080/admin-api/system/user/getAllMessage').then((res) => {
    yearPrice.value = res.data.data.yearPrice
  })
}


//动画
const textContainer1 = ref();
const floatingText1 = ref();
const shouldFloatUp1 = ref(false);

const textContainer2 = ref();
const floatingText2 = ref();
const shouldFloatUp2 = ref(false);

const textContainer3 = ref();
const floatingText3 = ref();
const shouldFloatUp3 = ref(false);

const textContainer4 = ref();
const floatingText4 = ref();
const shouldFloatUp4 = ref(false);


const textContainer5 = ref();
const floatingText5 = ref();
const shouldFloatUp5 = ref(false);

const textContainer6 = ref();
const floatingText6 = ref();
const shouldFloatUp6 = ref(false);


const videoContainer = ref();
const scaledVideo = ref();


let scale = 1;

function getTextPosition(element) {
  const rect = element.getBoundingClientRect();
  return {
    top: rect.top + window.scrollY,
    bottom: rect.bottom + window.scrollY,
    height: rect.height
  };
}

function getVideoPosition() {
  const rect = videoContainer.value.getBoundingClientRect();
  return {
    top: rect.top + window.scrollY,
    bottom: rect.bottom + window.scrollY,
    height: rect.height
  };
}

function updateScaleAndTextAnimation() {
  const textPos1 = getTextPosition(floatingText1.value);
  const textPos2 = getTextPosition(floatingText2.value);
  const textPos3 = getTextPosition(floatingText3.value);
  const textPos4 = getTextPosition(floatingText4.value);
  const textPos5 = getTextPosition(floatingText5.value);
  const textPos6 = getTextPosition(floatingText6.value);

  const videoPos = getVideoPosition();

  const scrollTop = window.scrollY || document.documentElement.scrollTop;

  if (scrollTop > videoPos.top - videoPos.height * 0.1) {
    const scrollDistanceFromStartPoint = scrollTop - (videoPos.top - videoPos.height * 0.1);
    const maxScrollDistance = videoPos.height * 0.8;
    const newScale = Math.max(0.65, 1 - (scrollDistanceFromStartPoint / maxScrollDistance));
    if (newScale !== scale) {
      scale = newScale;
      applyScale();
    }
  } else {
    if (scale !== 1) {
      scale = 1;
      applyScale();
    }
  }

  // 文字浮动逻辑
  handleFloatingText(textPos1, shouldFloatUp1);
  handleFloatingText(textPos2, shouldFloatUp2);
  handleFloatingText(textPos3, shouldFloatUp3);
  handleFloatingText(textPos4, shouldFloatUp4);
  handleFloatingText(textPos5, shouldFloatUp5);
  handleFloatingText(textPos6, shouldFloatUp6);
}

function handleFloatingText(textPos, shouldFloatUpRef) {
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  if (scrollTop > textPos.top - window.innerHeight - 120 && !shouldFloatUpRef.value) {
    shouldFloatUpRef.value = true;
  } else if (scrollTop < textPos.top - window.innerHeight - 50 && shouldFloatUpRef.value) {
    shouldFloatUpRef.value = false;
  }
}

function applyScale() {
  scaledVideo.value.style.transform = `scale(${scale})`;
}

const videoStyle = computed(() => ({
  width: '100%',
  height: 'auto',
  transformOrigin: 'center top',
  transition: 'transform 2s ease',
}));

onMounted(() => {
  getYearPrice()
  window.addEventListener('scroll', updateScaleAndTextAnimation);
});

onUnmounted(() => {
  window.removeEventListener('scroll', updateScaleAndTextAnimation);
});
</script>