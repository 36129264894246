<style scoped>
.container {
  position: relative;
  display: inline-block;
}

.popover-content {
  position: absolute;
  top: 36px;
  border: 1px solid #666666; /* 边框样式 */
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* 阴影效果 */
  z-index: 1000; /* 确保它在其他元素之上 */
  pointer-events: auto; /* 允许点击内部元素 */
}
.link {
  color: white;
  text-decoration: none;
  font-size: 15px;
}

.link:hover {
  color: skyblue;
  cursor: pointer;
}

@keyframes zoom {
  from {
    transform: scale(1); /* 起始时大小不变 */
  }
  to {
    transform: scale(1.1); /* 结束时放大到1.5倍 */
  }
}

.logout:hover {
  cursor: pointer;
}


.el-tooltip__trigger:focus-visible {
  outline: unset;
}

.mobile-dropdown {
  display: none;
}

@media screen and (max-width: 1350px) {
  .desktop-content {
    display: none;
  }

  .mobile-dropdown {
    display: inline-block;
    position: absolute;
    right: -10px; /* 右侧间距 */
    top: 15px; /* 垂直居中 */
    margin-right: 100px !important;
    margin-top: -1px;
  }

  .hidden-md {
    display: none !important;
  }

  .logo-container {
    margin-left: 0 !important;
    margin-top: 4px;
    justify-content: center !important;
  }

  .logo-link span {
    font-size: 16px !important;
  }

  .logo-container img {
    width: 36px;
    height: 36px;
  }

  /* 调整中间列布局 */
  .el-col-md-center {
    flex: 0 0 100%;
    max-width: 100%;
  }
}


</style>

<template>

  <!--  顶部头-->
  <div style="background-color:#FF2727;width: 100%;height: 50px;border-radius: 5px 5px 0 0;">
    <el-row>
      <el-col :span="9" class="hidden-md">
        <el-row style="margin-top: 15px;margin-left: 50%">

          <el-col :span="4">
            <router-link to="/" class="link">
              <span style="font-size: 15px; color: white">{{ t('top.Product') }}</span>
            </router-link>
          </el-col>

          <el-col :span="5">
            <span class="link" @click="goToBPageSection2">
              <span style="font-size: 15px; color: white">{{ t('top.Workflow') }}</span>
            </span>
          </el-col>

          <el-col :span="4">

            <!--            国内教程-->
            <a v-if="proxy.ip === t('ip.China')" href="https://linyunbim.cowtransfer.com/s/211d099cf2f549"
               class="link" style="font-size: 15px; color: white">{{ t('top.Tutorials') }}</a>

            <!--国外-->
            <a v-if="proxy.ip === t('ip.Foreign')"
               href="https://drive.google.com/file/d/1-kmQ-7DKACGTuHyleeC5IJKocmE6J2c2/view?usp=sharing"
               class="link" style="font-size: 15px; color: white">{{ t('top.Tutorials') }}</a>

            <a v-if="proxy.ip === t('ip.Localhost')" href="https://linyunbim.cowtransfer.com/s/211d099cf2f549"
               class="link" style="font-size: 15px; color: white">{{ t('top.Tutorials') }}</a>


          </el-col>

          <el-col :span="4">
            <span class="link" @click="goToBPageSection">
              <span style="font-size: 15px; color: white">{{ t('top.Pricing') }}</span>
            </span>
          </el-col>

          <el-col :span="6">
            <router-link to="/download" class="link">
              <span style="font-size: 15px; color: white">{{ t('top.Download') }}</span>
            </router-link>
          </el-col>

        </el-row>
      </el-col>


      <el-col :span="6" :xs="10">
        <div class="logo-container" style="display: flex; align-items: center;margin-left: 40%;">
          <router-link to="/">
            <img src="../../public/logo_white44-01-01-01.png" alt="麟云平台Logo"
                 style="margin-right: 5px;margin-top: 3px">
          </router-link>
          <router-link to="/" class="logo-link" style="text-decoration: none; color: white;">
            <span style="font-size: 15px;color: white">{{ t('top.LinYun') }}</span>
          </router-link>

        </div>

      </el-col>

      <el-col :span="7" style="margin-top: 15px;color: white">
        <!--        <el-button v-if="local" plain @click="dialogFormVisible = true"-->
        <!--                   style="background-color: #FF2727;color: white;border: none;font-size: 16px">-->
        <!--          {{t('top.Login')}}-->
        <!--        </el-button>-->


        <div class="container mobile-dropdown" @click="togglePopover">
          <el-icon size="25px" >
            <Operation />
          </el-icon>
          <div v-if="isVisible" class="popover-content" style="width: 90px;margin-left: -40px">
            <router-link to="/" class="link">
              <span style="font-size: 15px; color: white">{{ t('top.Product') }}</span>
            </router-link>
            <br>
            <span class="link" @click="goToBPageSection2">
              <span style="font-size: 15px; color: white">{{ t('top.Workflow') }}</span>
            </span>
            <br>
            <!--            国内教程-->
            <a v-if="proxy.ip === t('ip.China')" href="https://linyunbim.cowtransfer.com/s/211d099cf2f549"
               class="link" style="font-size: 15px; color: white">{{ t('top.Tutorials') }}</a>

            <!--国外-->
            <a v-if="proxy.ip === t('ip.Foreign')"
               href="https://drive.google.com/file/d/1-kmQ-7DKACGTuHyleeC5IJKocmE6J2c2/view?usp=sharing"
               class="link" style="font-size: 15px; color: white">{{ t('top.Tutorials') }}</a>

            <a v-if="proxy.ip === t('ip.Localhost')" href="https://linyunbim.cowtransfer.com/s/211d099cf2f549"
               class="link" style="font-size: 15px; color: white">{{ t('top.Tutorials') }}</a>
            <br>
            <span class="link" @click="goToBPageSection">
              <span style="font-size: 15px; color: white">{{ t('top.Pricing') }}</span>
            </span>
            <br>
            <router-link to="/download" class="link">
              <span style="font-size: 15px; color: white">{{ t('top.Download') }}</span>
            </router-link>

            <br>

            <a v-if="proxy.ip === t('ip.China')" href="http://47.116.115.210/login?register"
               style="text-decoration: none"><span class="link"
                                                   v-if="local">{{ t('top.Register') }}</span></a>
            <a v-if="proxy.ip === t('ip.Foreign')" href="http://47.89.229.58/login?register"
               style="text-decoration: none"><span class="link"
                                                   v-if="local">{{ t('top.Register') }}</span></a>
            <a v-if="proxy.ip === t('ip.Localhost')" href="http://localhost/login?register" style="text-decoration: none"><span
                class="link"  v-if="local">{{ t('top.Register') }}</span></a>

            <span v-if="!local" style="font-size: 15px; color: white" @click="logBack"
                  class="link">{{ t('top.GoBackstage') }}</span>
            <br>

            <span v-if="!local" style="font-size: 15px; color: white" @click="logout"
                  class="link">{{ t('top.Logout') }}</span>


            <span @click.stop="dialogFormVisible2 = true" class="link"  v-if="local" >{{
                t('top.Login')
              }}</span>

            <el-dialog v-model="dialogFormVisible2" :title="t('top.AccountLogin')" width="450"
                       style="margin-top: 20%;text-align: center">
              <el-form :model="form">
                <el-form-item :label="t('top.Username')" :label-width="formLabelWidth"
                              style="margin-left: 8%;margin-top: 10px">
                  <el-input style="width: 300px" v-model="form.username" autocomplete="off"/>
                </el-form-item>

                <el-form-item :label="t('top.Password')" :label-width="formLabelWidth" style="margin-left: 8%">
                  <el-input style="width: 300px" type="password" v-model="form.password" autocomplete="off"/>
                </el-form-item>
              </el-form>

              <template #footer>
                <div class="dialog-footer">
                  <el-button @click="dialogFormVisible2 = false">{{ t('top.Cancel') }}</el-button>
                  <el-button type="primary" @click="handleLogin2">
                    {{ t('top.Login') }}
                  </el-button>
                </div>
              </template>
            </el-dialog>
          </div>
        </div>


        <div class="desktop-content">


          <a v-if="proxy.ip === t('ip.China')" href="http://47.116.115.210/login?register"
             style="text-decoration: none"><span class="link" style="margin-left: -20px"
                                                 v-if="local">{{ t('top.Register') }}</span></a>
          <a v-if="proxy.ip === t('ip.Foreign')" href="http://47.89.229.58/login?register"
             style="text-decoration: none"><span class="link" style="margin-left: -20px"
                                                 v-if="local">{{ t('top.Register') }}</span></a>
          <a v-if="proxy.ip === t('ip.Localhost')" href="http://localhost/login?register" style="text-decoration: none"><span
              class="link" style="margin-left: -20px" v-if="local">{{ t('top.Register') }}</span></a>


          <span @click="dialogFormVisible = true" class="link" style="margin-left: 1rem" v-if="local">{{t('top.Login') }}</span>


          <el-dialog v-model="dialogFormVisible" :title="t('top.AccountLogin')" width="450"
                     style="margin-top: 20%;text-align: center">
            <el-form :model="form">
              <el-form-item :label="t('top.Username')" :label-width="formLabelWidth"
                            style="margin-left: 8%;margin-top: 10px">
                <el-input style="width: 300px" v-model="form.username" autocomplete="off"/>
              </el-form-item>

              <el-form-item :label="t('top.Password')" :label-width="formLabelWidth" style="margin-left: 8%">
                <el-input style="width: 300px" type="password" v-model="form.password" autocomplete="off"/>
              </el-form-item>
            </el-form>

            <template #footer>
              <div class="dialog-footer">
                <el-button @click="dialogFormVisible = false">{{ t('top.Cancel') }}</el-button>
                <el-button type="primary" @click="handleLogin">
                  {{ t('top.Login') }}
                </el-button>
              </div>
            </template>
          </el-dialog>
          <span v-if="!local" style="font-size: 15px; color: white;margin-left: -100px">{{
              t('top.Honorific')
            }} : {{ user.nickname }}</span>
          <span v-if="!local" style="margin-left: 1rem;font-size: 15px; color: white" @click="logout"
                class="link">{{ t('top.Logout') }}</span>
          <span v-if="!local" style="margin-left: 1rem;font-size: 15px; color: white" @click="logBack"
                class="link">{{ t('top.GoBackstage') }}</span>

          <!--        <el-select v-model="currentLocale" @change="changeLanguage(currentLocale)" style="margin-left: 30px;width: 100px;" size="small" >-->
          <!--          <el-option-->
          <!--              v-for="lang in [{'value': 'zh-CN', 'label': '简体中文'}, {'value': 'en', 'label': 'English'}]"-->
          <!--              :key="lang.value"-->
          <!--              :label="lang.label"-->
          <!--              :value="lang.value">-->
          <!--          </el-option>-->
          <!--        </el-select>-->

          <el-dropdown @command="changeLanguage" style="margin-left: 30px;">
          <span style="font-size: 15px; color: white" class="link">
            {{ currentLocale === 'zh-CN' ? '简体中文' : 'English' }}
            <el-icon class="el-icon--right">
          <arrow-down/>
            </el-icon>
          </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-for="lang in languages"
                                  :key="lang.value"
                                  :command="lang.value">
                  {{ lang.label }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-col>

    </el-row>
  </div>

</template>

<script lang="js" setup>
import {getCurrentInstance, onMounted, reactive, ref} from 'vue';
import axios from "axios";
import {ElMessage, ElMessageBox} from "element-plus";
import JSEncrypt from "jsencrypt";
import {useRouter} from 'vue-router';
import {useI18n} from 'vue-i18n';
import {ArrowDown} from "@element-plus/icons-vue";


// 控制popover的显示与隐藏
const isVisible = ref(false);

function togglePopover(event) {
  // 如果已经是显示状态则不再切换
  if (!isVisible.value) {
    isVisible.value = true;
  }
  event.stopPropagation();
}

// 点击页面其他地方时关闭popover
document.addEventListener('click', (event) => {
  const popoverContent = document.querySelector('.popover-content');
  if (popoverContent && !event.target.closest('.container')) {
    isVisible.value = false;
  }
});



const {proxy} = getCurrentInstance();
// 使用 useI18n 钩子
const {t, locale} = useI18n();

// 当前语言的响应式变量
const currentLocale = ref(locale.value);
const languages = [
  {value: 'zh-CN', label: '简体中文'},
  {value: 'en', label: 'English'}
];
// 切换语言的函数
const changeLanguage = (lang) => {
  locale.value = lang;
  currentLocale.value = lang;
  // 将新的语言选择保存到 localStorage
  localStorage.setItem('locale', lang);
};

const router = useRouter();
const dialogFormVisible = ref(false)
const dialogFormVisible2 = ref(false)
const formLabelWidth = '50px'

const form = reactive({
  username: '',
  password: '',
  nickName: '',
  tenantName: '',
  tenantId: '',
})


const handleLogin = () => {
  axios.post(proxy.ip + ':48080/admin-api/system/auth/getTenant?username=' + form.username).then((response) => {
    if (response.data.code === 0) {
      form.tenantName = response.data.data
      axios.get(proxy.ip + ':48080/admin-api/system/tenant/get-id-by-name?name=' + form.tenantName).then((response) => {
        if (response.data.code === 0) {
          form.tenantId = response.data.data
          axios.post(proxy.ip + ':48080/admin-api/system/auth/login', form, {
            headers: {
              'Content-Type': 'application/json', // 如果你正在发送JSON数据，这通常是必要的
              'tenant-id': form.tenantId // 在这里添加你的tenant-id
            }
          }).then((response) => {
            if (response.data.code === 0) {
              user.value = response.data.data
              axios.get(proxy.ip + ':48080/admin-api/system/auth/getNickname?userId=' + user.value.userId).then((response) => {
                if (response.data.code === 0) {
                  user.value.nickname = response.data.data
                  user.value.username = form.username
                  form.password = encryptor.encrypt(form.password)
                  user.value.password = form.password
                  localStorage.setItem('token', JSON.stringify(user.value));
                  // 30天
                  const expirationTime = 1000 * 60 * 60 * 24 * 30  // 转换为毫秒
                  // 计算过期时间戳
                  const expirationDate = Date.now() + expirationTime;
                  localStorage.setItem('token_expiration', expirationDate.toString());
                  ElMessage.success(t('top.LoginSuccessful'))
                  dialogFormVisible.value = false
                  setTimeout(() => {
                    location.reload()
                  }, 700)
                }
              })
            } else {
              ElMessage.error(response.data.msg)
            }
          })
        } else {
          ElMessage.error(response.data.msg)
        }
      })
    } else if (response.data.code === 1002000000) {
      ElMessage.error(t('top.AccountPasswordIsIncorrect'))
    } else if (response.data.code === 1002000002) {
      ElMessage.error(t('top.AccountExpired'))
    }
  })
}

const handleLogin2 = () => {
  axios.post(proxy.ip + ':48080/admin-api/system/auth/getTenant?username=' + form.username).then((response) => {
    if (response.data.code === 0) {
      form.tenantName = response.data.data
      axios.get(proxy.ip + ':48080/admin-api/system/tenant/get-id-by-name?name=' + form.tenantName).then((response) => {
        if (response.data.code === 0) {
          form.tenantId = response.data.data
          axios.post(proxy.ip + ':48080/admin-api/system/auth/login', form, {
            headers: {
              'Content-Type': 'application/json', // 如果你正在发送JSON数据，这通常是必要的
              'tenant-id': form.tenantId // 在这里添加你的tenant-id
            }
          }).then((response) => {
            if (response.data.code === 0) {
              user.value = response.data.data
              axios.get(proxy.ip + ':48080/admin-api/system/auth/getNickname?userId=' + user.value.userId).then((response) => {
                if (response.data.code === 0) {
                  user.value.nickname = response.data.data
                  user.value.username = form.username
                  form.password = encryptor.encrypt(form.password)
                  user.value.password = form.password
                  localStorage.setItem('token', JSON.stringify(user.value));
                  // 30天
                  const expirationTime = 1000 * 60 * 60 * 24 * 30  // 转换为毫秒
                  // 计算过期时间戳
                  const expirationDate = Date.now() + expirationTime;
                  localStorage.setItem('token_expiration', expirationDate.toString());
                  ElMessage.success(t('top.LoginSuccessful'))
                  dialogFormVisible2.value = false
                  setTimeout(() => {
                    location.reload()
                  }, 700)
                }
              })
            } else {
              ElMessage.error(response.data.msg)
            }
          })
        } else {
          ElMessage.error(response.data.msg)
        }
      })
    } else if (response.data.code === 1002000000) {
      ElMessage.error(t('top.AccountPasswordIsIncorrect'))
    } else if (response.data.code === 1002000002) {
      ElMessage.error(t('top.AccountExpired'))
    }
  })
}

const logout = () => {
  ElMessageBox.confirm(
      t('top.ExitReminderText'),
      t('top.ExitReminder'),
      {
        confirmButtonText: t('top.Affirm'),
        cancelButtonText: t('top.Cancel'),
        type: 'warning',
      }
  )
      .then(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('token_expiration');
        ElMessage.success(t('top.LogoutSuccessful'))
        setTimeout(() => {
          location.reload()
        }, 700)
      })
      .catch(() => {
        ElMessage({
          type: 'info',
          message: t('top.CancelLogout'),
        })
      })
}

const publicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAn8wru5QjuYhj7pFNFdJroP5JmLQHbciRiIXrjlA4mfk+DFhJ8sPiiKQmZxDjfjip++tcChpKfhIqGrlVe3lJiojfV1wUaB' +
    'nf8GYc0+gJQuy1u94VYtwYC7Rdv/RIhMil2yiIsD+QX2c/gjEXxTXqO87KSNUdFJKCxHLUE3MY+6QAcV2WV4cp/z/x+BhYSZLG6+o8hKjyLtDMfTuJwZj2398QTcZ/6WlZWg2ScKUUxZX+BUj8GujT2jQk' +
    'WDMbvtLuBXT7KDR5OXo0cZNULAH9waaBd3rezzZCp8behKGPBeBbi2/CJRKcwDcJjzB51vrXwAW78mOvQ4m2UwS1culwcwIDAQAB'

const encryptor = new JSEncrypt();
encryptor.setPublicKey(publicKey);

const logBack = () => {
  if (proxy.ip === t('ip.China')) {
    window.location.href = 'http://47.116.115.210/login?redirect=/index?14c4b06b824ec593239362517f538b29=' + user.value.username + '&5f4dcc3b5aa765d61d8327deb882cf99=' + user.value.password
  }
  if (proxy.ip === t('ip.Foreign')) {
    window.location.href = 'http://47.89.229.58/login?redirect=/index?14c4b06b824ec593239362517f538b29=' + user.value.username + '&5f4dcc3b5aa765d61d8327deb882cf99=' + user.value.password
  }
  if (proxy.ip === t('ip.Localhost')) {
    window.location.href = 'http://localhost/login?redirect=/index?14c4b06b824ec593239362517f538b29=' + user.value.username + '&5f4dcc3b5aa765d61d8327deb882cf99=' + user.value.password
  }
}

const price = ref(null);

//点击价格下滑
const goToBPageSection = () => {
  router.push({path: '/', hash: '#specific-section'});
}

//工作流下滑
const goToBPageSection2 = () => {
  router.push({path: '/', hash: '#workflow'});
}

const goToBPageSection3 = () => {
  router.push({path: '/', hash: '#download'});
}

const local = ref();
const user = ref({
  userId: '',
  accessToken: '',
  username: '',
  password: '',
  tenantId: '',
  nickname: '',
  version: '',
})
onMounted(() => {

  if (localStorage.getItem('token_expiration') !== null && Date.now() > localStorage.getItem('token_expiration')) {
    localStorage.removeItem('token');
    localStorage.removeItem('token_expiration');
    ElMessage.error(t('top.Expire'))
  }
  if (localStorage.getItem('token') === null) {
    local.value = true;
  } else {
    user.value = JSON.parse(localStorage.getItem('token'))
    if (user.value.nickname === null){
      axios.get(proxy.ip + ':48080/admin-api/system/auth/getNickname?userId=' + user.value.userId).then((response) => {
        if (response.data.code === 0) {
          user.value.nickname = response.data.data
        }
      })
    }
  }
  const savedLocale = localStorage.getItem('locale');
  if (savedLocale) {
    locale.value = savedLocale;
    currentLocale.value = savedLocale;
  }


})
</script>